<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit details</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Daytime Telephone *"
            v-model="fields.daytime_telephone"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Evening Telephone *"
            v-model="fields.evening_telephone"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Address Line 1 *"
            v-model="fields.line_1"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.line_2"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Address Line 3"
            v-model="fields.line_3"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.county"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="unsuitable_collection_dates"
            v-model="fields.unsuitable_collection_dates"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="suitable_collection_timeframe"
            v-model="fields.suitable_collection_timeframe"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="desired_litter"
            v-model="fields.desired_litter"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="dog_living_conditions"
            v-model="fields.dog_living_conditions"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-textarea
            label="puppy_training_details"
            v-model="fields.puppy_training_details"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-text-field
            label="size_first_choice"
            v-model="fields.size_first_choice"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="lifestyle"
            v-model="fields.lifestyle"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="gender_preference"
            v-model="fields.gender_preference"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-switch
            label="Does anybody in your household have a disability or particular need which may need to be taken into consideration when matching a puppy with your family?"
            v-model="fields.household_disability"
            inset
          ></v-switch>

          <v-text-field
            label="additional_animals"
            v-model="fields.additional_animals"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="previous_ownership_experience"
            v-model="fields.previous_ownership_experience"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="care_provisions"
            v-model="fields.care_provisions"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="carers_work_schedule"
            v-model="fields.carers_work_schedule"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="list_household_members"
            v-model="fields.list_household_members"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="enclosed_garden"
            v-model="fields.enclosed_garden"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="adult_owner_status"
            v-model="fields.adult_owner_status"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="under_18s_confident_reaction"
            v-model="fields.under_18s_confident_reaction"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="regular_visitors"
            v-model="fields.regular_visitors"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="relinquish_pet_prior"
            v-model="fields.relinquish_pet_prior"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="puppy_a_gift"
            v-model="fields.puppy_a_gift"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="puppy_needs_toilet"
            v-model="fields.puppy_needs_toilet"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="household_disability_information"
            v-model="fields.household_disability_information"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-switch
            label="Do you have any under 18 year olds living at home or visiting regularly?"
            v-model="fields.under_18s"
            inset
          ></v-switch>
          <v-text-field
            label="Please list the children's genders and ages below"
            v-model="fields.under_18s_details"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-switch
            label="Have the children been exposed to dogs much?"
            v-model="fields.under_18s_exposure"
            inset
          ></v-switch>

          <v-text-field
            label="any_gender"
            v-model="fields.any_gender"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="single_colour_litter"
            v-model="fields.single_colour_litter"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="coat_colour_important"
            v-model="fields.coat_colour_important"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="black_parti"
            v-model="fields.black_parti"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="caramel"
            v-model="fields.caramel"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="gold_cream"
            v-model="fields.gold_cream"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="red_gold"
            v-model="fields.red_gold"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="chocolate_cafe_lavender_parchment"
            v-model="fields.chocolate_cafe_lavender_parchment"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="black_silver_blue"
            v-model="fields.black_silver_blue"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="chocolate_parti"
            v-model="fields.chocolate_parti"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="size_second_choice"
            v-model="fields.size_second_choice"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="provisions_for_dog"
            v-model="fields.provisions_for_dog"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="additional_animals_details"
            v-model="fields.additional_animals_details"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="puppy_gift_for_household"
            v-model="fields.puppy_gift_for_household"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-switch
            label="Must your puppy be non-shedding"
            v-model="fields.non_shedding"
            inset
          ></v-switch>

          <v-text-field
            label="anything_else_to_tell"
            v-model="fields.anything_else_to_tell"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="found_lhl"
            v-model="fields.found_lhl"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="coat_type_third_choice"
            v-model="fields.coat_type_third_choice"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="coat_type_second_choice"
            v-model="fields.coat_type_second_choice"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="coat_type_first_choice"
            v-model="fields.coat_type_first_choice"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="size_third_choice"
            v-model="fields.size_third_choice"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-textarea
            label="puppy_gift_prior_knowledge"
            v-model="fields.puppy_gift_prior_knowledge"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>
          <v-textarea
            label="carer_schedule"
            v-model="fields.carer_schedule"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="does_anyone_in_your_household_have_allergies_or_asthma"
            v-model="
              fields.does_anyone_in_your_household_have_allergies_or_asthma
            "
            type="text"
            outlined
            background-color="white"
          ></v-textarea>
          <v-textarea
            label="relinquish_pet_details"
            v-model="fields.relinquish_pet_details"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="if_yes_please_tell_us_more"
            v-model="fields.if_yes_please_tell_us_more"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    applicationId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      fields: {
        list_household_members: null,
        carers_work_schedule: null,
        care_provisions: null,
        previous_ownership_experience: null,
        additional_animals: null,
        household_disability: null,
        puppy_training_details: null,
        dog_living_conditions: null,
        desired_litter: null,
        suitable_collection_timeframe: null,
        unsuitable_collection_dates: null,
        size_first_choice: null,
        lifestyle: null,
        gender_preference: null,
        coat_colour_important: null,
        single_colour_litter: null,
        any_gender: null,
        under_18s: false,
        under_18s_details: null,
        under_18s_exposure: null,
        under_18s_confident_reaction: null,
        adult_owner_status: null,
        enclosed_garden: null,
        regular_visitors: null,
        relinquish_pet_prior: null,
        puppy_a_gift: null,
        puppy_needs_toilet: null,
        household_disability_information: null,
        black_silver_blue: null,
        chocolate_cafe_lavender_parchment: null,
        red_gold: null,
        gold_cream: null,
        caramel: null,
        black_parti: null,
        chocolate_parti: null,
        size_second_choice: null,
        size_third_choice: null,
        coat_type_first_choice: null,
        coat_type_second_choice: null,
        coat_type_third_choice: null,
        found_lhl: null,
        anything_else_to_tell: null,
        non_shedding: null,
        puppy_gift_for_household: null,
        additional_animals_details: null,
        provisions_for_dog: null,
        puppy_gift_prior_knowledge: null,
        carer_schedule: null,
        does_anyone_in_your_household_have_allergies_or_asthma: null,
        relinquish_pet_details: null,
        email_confirmation: null,
        if_yes_please_tell_us_more: null,
        phone: null,
        email: null,
        mobile: null,
        evening_telephone: null,
        daytime_telephone: null,
        county: null,
        city: null,
        postcode: null,
        line_3: null,
        line_2: null,
        line_1: null,
      },
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },
    customers() {
      let customers = this.$store.getters["lhl/customers/all"];

      return customers;
    },
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;

        this.fields.list_household_members = application.list_household_members;
        this.fields.carers_work_schedule = application.carers_work_schedule;
        this.fields.care_provisions = application.care_provisions;
        this.fields.previous_ownership_experience =
          application.previous_ownership_experience;
        this.fields.additional_animals = application.additional_animals;
        this.fields.household_disability = application.household_disability;
        this.fields.puppy_training_details = application.puppy_training_details;
        this.fields.dog_living_conditions = application.dog_living_conditions;

        this.fields.desired_litter = application.desired_litter;
        this.fields.suitable_collection_timeframe =
          application.suitable_collection_timeframe;
        this.fields.unsuitable_collection_dates =
          application.unsuitable_collection_dates;
        this.fields.size_first_choice = application.size_first_choice;

        this.fields.lifestyle = application.lifestyle;
        this.fields.gender_preference = application.gender_preference;
        this.fields.coat_colour_important = application.coat_colour_important;

        this.fields.single_colour_litter = application.single_colour_litter;
        this.fields.any_gender = application.any_gender;
        this.fields.under_18s = application.under_18s;
        this.fields.under_18s_details = application.under_18s_details;

        this.fields.under_18s_exposure = application.under_18s_exposure;

        this.fields.under_18s_confident_reaction =
          application.under_18s_confident_reaction;
        this.fields.adult_owner_status = application.adult_owner_status;
        this.fields.enclosed_garden = application.enclosed_garden;
        this.fields.regular_visitors = application.regular_visitors;
        this.fields.relinquish_pet_prior = application.relinquish_pet_prior;
        this.fields.puppy_a_gift = application.puppy_a_gift;

        this.fields.puppy_needs_toilet = application.puppy_needs_toilet;
        this.fields.household_disability_information =
          application.household_disability_information;

        this.fields.black_silver_blue = application.black_silver_blue;
        this.fields.chocolate_cafe_lavender_parchment =
          application.chocolate_cafe_lavender_parchment;
        this.fields.red_gold = application.red_gold;
        this.fields.gold_cream = application.gold_cream;
        this.fields.caramel = application.caramel;

        this.fields.chocolate_parti = application.chocolate_parti;
        this.fields.black_parti = application.black_parti;

        this.fields.size_second_choice = application.size_second_choice;
        this.fields.size_third_choice = application.size_third_choice;
        this.fields.coat_type_first_choice = application.coat_type_first_choice;
        this.fields.coat_type_second_choice =
          application.coat_type_second_choice;

        this.fields.coat_type_third_choice = application.coat_type_third_choice;
        this.fields.found_lhl = application.found_lhl;

        this.fields.anything_else_to_tell = application.anything_else_to_tell;

        this.fields.non_shedding = application.non_shedding;
        this.fields.puppy_gift_for_household =
          application.puppy_gift_for_household;
        this.fields.additional_animals_details =
          application.additional_animals_details;

        this.fields.provisions_for_dog = application.provisions_for_dog;
        this.fields.puppy_gift_prior_knowledge =
          application.puppy_gift_prior_knowledge;
        this.fields.carer_schedule = application.carer_schedule;
        this.fields.does_anyone_in_your_household_have_allergies_or_asthma =
          application.does_anyone_in_your_household_have_allergies_or_asthma;
        this.fields.relinquish_pet_details = application.relinquish_pet_details;

        this.fields.email_confirmation = application.email_confirmation;
        this.fields.if_yes_please_tell_us_more =
          application.if_yes_please_tell_us_more;

        this.fields.phone = application.admins.phone;
        this.fields.email = application.admins.email;
        this.fields.mobile = application.admins.mobile;
        this.fields.evening_telephone = application.admins.evening_telephone;
        this.fields.daytime_telephone = application.admins.daytime_telephone;
        this.fields.county = application.admins.county;
        this.fields.city = application.admins.city;
        this.fields.postcode = application.admins.postcode;
        this.fields.line_3 = application.admins.line_3;
        this.fields.line_2 = application.admins.line_2;
        this.fields.line_1 = application.admins.line_1;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        applicationId: this.$route.params.applicationId,
        fields: this.fields,
      };

      payload.fields.type = "maintenance";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.hashed_id = null;
      this.fields.customer_id = null;
      this.fields.breed_id = null;
    },
  },
};
</script>
